import React from 'react';

import axios from 'axios';

// retreive value for :
//{id, source, timestamp, frame, longitude, latitude, tncPort, azimuth, elevation, fDown, version, noradID}).

export default class HomeSidsFrame extends React.Component {
  state = {
    sidsFrame: [],
  	count: []
  }

  componentDidMount() {
 
	  	 
      axios.get('/api/V1/database/sidsDatacount').then(res => {
          const count = res.data;
          this.setState({ count });
          
          
        })
 
        axios.get('/api/V1/database/lastSidsData').then(res => {
        const sidsFrame = res.data;
        this.setState({ sidsFrame });
        console.log(this.state.sidsFrame);
      })
	 
      

  }

  render() {
    return (
    		
	<div>
	<h1> Enregistrement courant </h1>
	<p>Nombre d enregistrement : {this.state.count} </p>
	 <p> Dernier enregistrement </p>
	
     <ul>
     	<p>Source: <input maxLength={40} name="Source" type="text" defaultValue={this.state.sidsFrame.source} />&nbsp; &nbsp; &nbsp; Timestamp :&nbsp;&nbsp;<input name="Timestamp" type="text" defaultValue={this.state.sidsFrame.timestamp} /><br />
		Satellite :&nbsp;&nbsp;<input name="satellite" type="text" defaultValue={this.state.sidsFrame.noradID} /><br />
	   Frame: <input size={150} name="Frame" type="text" defaultValue={this.state.sidsFrame.frame} /></p>
							
     </ul>
	  
	</div>  
    )
  }
}