import React from 'react';

import axios from 'axios';

// retreive value for :
//{id, source, timestamp, frame, longitude, latitude, tncPort, azimuth, elevation, fDown, version, noradID}).





export default class Classement extends React.Component {
  state = {
    tableauAmicalsat: [],
    tableauEntrySat: [],
  tableaugen: [],
  tableauUVSQsat: []
  
  }

  componentDidMount() {
 
	   axios.get('/classement2/44429').then(res => {
	        const tableauEntrySat = res.data;
	        this.setState({ tableauEntrySat });
	      })
	  axios.get('/classement3/99836/46287').then(res => {
        const tableauAmicalsat = res.data;
        this.setState({ tableauAmicalsat });
      })
      	  axios.get('api/V2/database/classementByNoradIds?noradID1=99749&noradID2=47438').then(res => {
        const tableauUVSQsat = res.data;
        this.setState({ tableauUVSQsat });
      })
   axios.get('/classement').then(res2 => {
        const tableaugen = res2.data;
        this.setState({ tableaugen });
      })
  }
  
  r

  render() {
	    return (
		<div>
		 <h1>Amicalsat Data Providers</h1>
		 
	      <table id="DataProvider" class="Classement">
	      <thead><tr> <th>Name</th><th>Upload</th> </tr> </thead>
	        <tbody> 

	          {
		   this.state.tableauAmicalsat.map(function(item) {             
							return (
									 <tr>
									 <td>{item.source}</td><td>{item.source_count}</td>
									 </tr>
													
								)
							})
	          }
	     
	        </tbody></table>
	        
	        
	        
			 <h1>UVSQsat Data Providers</h1>
			 
		      <table id="DataProviderUVSQsat" class="Classement">
		      <thead><tr> <th>Name</th><th>Upload</th> </tr> </thead>
		        <tbody> 

		          {
			   this.state.tableauUVSQsat.map(function(item) {             
								return (
										 <tr>
										 <td>{item.source}</td><td>{item.source_count}</td>
										 </tr>
														
									)
								})
		          }
		     
		        </tbody></table>

		 <h1>Entrysat Data Providers</h1>
		 
	      <table id="DataProviderEntrysat" class="Classement">
	      <thead><tr> <th>Name</th><th>Upload</th> </tr> </thead>
	        <tbody> 

	          {
		   this.state.tableauEntrySat.map(function(item) {             
							return (
									 <tr>
									 <td>{item.source}</td><td>{item.source_count}</td>
									 </tr>
													
								)
							})
	          }
	     
	        </tbody></table>
	        
			 <h1>All Data Providers</h1>
			 
		      <table id="DataProviderall" class="Classement">
		      <thead><tr> <th>Name</th><th>Upload</th> </tr> </thead>
		        <tbody> 

		          {
			   this.state.tableaugen.map(function(item) {             
								return (
										 <tr>
										 <td>{item.source}</td><td>{item.source_count}</td>
										 </tr>
														
									)
								})
		          }
		     
		        </tbody></table>
		</div> 
	    )
	  }
	}
