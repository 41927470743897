import React from 'react'

class Message extends React.Component {
	render () {
		return (
		
				<div>
				<h1> Introduction </h1>
				<p>This site is an experimental site (V0.70) allowing to test telemetry decoding software and to work on data analysis. This site is based on sources from the <a href="https://code.electrolab.fr/xtof/josast/wikis/home" target="_blank"> JOSAST </a>  project.</p>
			    <p> Three satellites use this database: </p>
			    
				<p> Ce site est un site expérimental (V0.70) permettant de tester les logiciels de décodage de télémesure  et de travailler sur l'analyse des données. Ce site basé sur des sources du projet <a href="https://code.electrolab.fr/xtof/josast/wikis/home" target="_blank"> JOSAST </a> . </p>
			    <p> Trois satellites utilisent cette base de données : </p>
				
				
				<ul>
	        		<li>  <a href="https://www.csut.eu/" target="_blank" > EntrySat </a> . </li>
	        		<li>  <a href="http://amicalsat.univ-grenoble-alpes.fr/" target="_blank" > Amicalsat </a> . </li>
	        		<li>  <a href="http://uvsq-sat.projet.latmos.ipsl.fr/?ong=Ham-Radio" target="_blank" > UVSQsat </a> . </li>
	            </ul>

		        <p>  Les données collectées sont interprétées et affichées sous forme graphique pour :    
		       </p>
		        
				<ul>
        		<li>   <a href="https://amsat.electrolab.fr/grafana" target="_blank"> Dashboard Entrysat </a>  </li>
        		<li>    <a href="https://amsat.electrolab.fr/grafana/d/ujwd-SaMz/uvsqsat?orgId=1" target="_blank"> Dashboard UVSQsat (Based on received timestamp) </a> </li>
        		<li>    <a href="https://amsat.electrolab.fr/grafana/d/YqPC5y-Gz/uvsqsat-internat-frame-time?orgId=1" target="_blank"> Dashboard UVSQsat (Based on received decoded frame timestamp) </a></li>
        		
            </ul>
		      
		        <p> Les API sont documentées via le lien :  <a href="https://amsat.electrolab.fr/swagger-ui.html"  target="_blank"> API </a> </p>
		    

		        </div>
			
		
		
		)
		
	
}
}
export default Message