import React, { Component } from 'react'

import './css/knacss.css'
import './css/styles.css'
/* import './App.css' */
import Home from './home'
import Message from './message'
import Ping from './Ping'
import HomeSidsFrame from './HomeSidsFrame'
import HomeSidsFrameV2 from './HomeSidsFrameV2'
import Classement from './Classement'

class App extends Component {
  render() {
	  
    return (
    		
    		
    		<div id="page">
    		  { /* DIV : Aucun sens sémantique - zone géographique */}
    		<header id="header" role="banner"><Home home />  
				<meta http-equiv="refresh" content="0; URL=https://amsat.electrolab.fr/spa/#/" />
			</header>
  		    <aside class="aside"  >  <Classement classement/> </aside>   		  
    		  <nav>{/* Nav. principale de la page -> site */}</nav>     
				<p>If you are not redirected  , <a href="https://amsat.electrolab.fr/spa/#/">click here</a>.</p>
    		  <article className="content"><Message message/></article>
    		  <article className="content"><HomeSidsFrame homesidsFrame/></article>
    		  <article className="content"><HomeSidsFrameV2 homesidsFrameV2/></article>
    		</div>


	)
	
  }
}

export default App