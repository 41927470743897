import React from 'react'

class Home extends React.Component {
	render () {
		return (
		
				<div>
				  <h1 style={{ textAlign: "center" }}>Serveur de base de données AMSAT-F</h1>
				  <h2 style={{ textAlign: "center" }}>Amsat-F data base server</h2>
				</div>
		
		)
		
}
}
export default Home
