import React from 'react';

import axios from 'axios';

// retreive value for :
// {"status":"OK","timestamp":1560161585010"}

export default class Ping extends React.Component {
  state = {
    pingValue: []
  }

  componentDidMount() {

   axios.get('/api/V1/ping').then(res => {
        const pingValue = res.data;
        this.setState({ pingValue });
		console.log(pingValue);
      })
  }

  render() {
    return (

	<div>
	

	  {
	  this.state.pingValue.map(function(item) {             
						return (
								 <p> Version :  {item.version} </p>
								 					
							)
						})
	  }

	</div>  
    )
  }
}